import _export from "../internals/export";
import _arrayLastIndexOf from "../internals/array-last-index-of";
var $ = _export;
var lastIndexOf = _arrayLastIndexOf; // `Array.prototype.lastIndexOf` method
// https://tc39.es/ecma262/#sec-array.prototype.lastindexof
// eslint-disable-next-line es-x/no-array-prototype-lastindexof -- required for testing

$({
  target: "Array",
  proto: true,
  forced: lastIndexOf !== [].lastIndexOf
}, {
  lastIndexOf: lastIndexOf
});
export default {};